import React, {useEffect, useRef, useState} from 'react';
// import loading from '../assets/images/loading.svg'
import styles from '../styles/Home.module.css';
import {useTranslation} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import {Dialog} from '@headlessui/react'
import {Bars3Icon, XMarkIcon} from '@heroicons/react/24/outline'
import SEO from "./SEO";

import Services from './Services';
import BrainCog_Born from './BrainCog_Born';
import Datasets from './Datasets';
import {Fade} from "react-awesome-reveal";

function Home() {
    const {t, i18n} = useTranslation(['home', 'html_title', 'common']);
    const isChinese = i18n.language === 'zh';
    const introLeftText = t('introLeftText', {returnObjects: true});
    const introRightText = t('introRightText', {returnObjects: true});

    const heroVideoRef = useRef(null);

  useEffect(() => {
    if (heroVideoRef.current) {
      heroVideoRef.current.playbackRate = 0.6;
    }
  }, []); // Run this effect only once, when the component mounts    

    const services = [
        {name: "mind_talk", href: 'http://mind-engine.org/mind-talk/', intro: t("service_intro_mind_talk")},
        {name: "mind_mate", href: 'http://mind-engine.org/mind-mate/', intro: t("service_intro_mind_mate")},
        {name: "symbiosis_panorama", href: 'http://symbiosis-panorama.ai-for-sdgs.academy/cn/', intro: t("service_intro_mind_talk")},
        {name: "cie", href: 'http://cultural-interactions-engine.ai-for-sdgs.academy/', intro: t("service_intro_mind_talk")},
        {name: "digital_philosophers", href: 'http://cn.digital-philosophers.org/', intro: t("service_intro_mind_talk")},
        {name: "sea", href: 'https://safe-and-ethical.ai/', intro: t("service_intro_mind_talk")},
    ];

    const organizations_cn = [
        {name: t("organization_name_1"), href: 'javascript:void(0)', intro: t("organization_intro_1")},
        {name: t("organization_name_2"), href: 'javascript:void(0)', intro: t("organization_intro_2")},
        {name: t("organization_name_3"), href: 'javascript:void(0)', intro: t("organization_intro_3")},
        {name: t("organization_name_4"), href: 'https://long-term-ai.center/', intro: t("organization_intro_4")},
    ];

    const organizations_en = [
        {name: t("organization_name_4"), href: 'https://long-term-ai.center/', intro: t("organization_intro_4")},
        {name: t("organization_name_1"), href: 'javascript:void(0)', intro: t("organization_intro_1")},
        {name: t("organization_name_2"), href: 'javascript:void(0)', intro: t("organization_intro_2")},
        {name: t("organization_name_3"), href: 'javascript:void(0)', intro: t("organization_intro_3")},
    ];

    return (
        <div className="bg-black pb-64">
            <SEO title={t('home', {ns: 'html_title'})}></SEO>
            {/*hero*/}
            <div
                className={`relative isolate px-6 pt-14 lg:px-8 h-screen bg-animation ${styles.bg_animation} flex items-center justify-center`}>
                {/*            <div className={`relative isolate px-6 pt-14 lg:px-8 h-screen bg-animation flex items-center justify-center`}>
                <video ref={heroVideoRef} className="absolute inset-0 w-full h-full object-cover -z-2" poster={process.env.PUBLIC_URL + "/images/hero_background/dancing_numbers.jpg"} autoPlay preload="auto" loop muted>
                    <source src={process.env.PUBLIC_URL + "/videos/dancing_numbers_1m.mp4"} type="video/mp4"/>
                     添加其他视频格式的source标签，如webm, ogg等
                    Your browser does not support the video tag.
                </video>*/}
                <div className="absolute inset-0 bg-neutral-950/30 -z-1"></div>
                <div className="mx-auto max-w-4xl py-32 sm:py-48 lg:py-48 z-0">
                    <div className="hidden sm:mb-16 sm:flex sm:justify-center">
                        <div
                            className="relative rounded-full px-6 py-1 text-sm leading-6 text-gray-300 ring-1 ring-gray-100/50 hover:ring-gray-100/40"
                        >
                            {t("announcing")}.{'  '}
                            <a href="#services" className="font-semibold text-indigo-400">
                                <span className="absolute inset-0" aria-hidden="true"/>
                                {t("get_started")} <span aria-hidden="true">&rarr;</span>
                            </a>
                        </div>
                    </div>
                    <div className="text-center">
                        <h1 className={`text-5xl font-bold tracking-[.1em] bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-neutral-50/100 to-neutral-400/60 sm:text-[5.5rem] leading-normal ${isChinese ? 'sm:tracking-[.45em]' : 'sm:tracking-[.1em]'}`}>
                            {t('hero_heading_1')}
                        </h1>
                        <p className={`mt-12 text-xl font-light font-sans leading-8 text-neutral-400 sm:text-neutral-300 sm:text-2xl sm:leading-8 ${isChinese ? 'tracking-[.25em] sm:tracking-[.3em]' : 'tracking-[.02em] sm:tracking-[.02em]'}`}>
                            {t('hero_heading_2')}
                        </p>
                        <div className="mt-[7rem] flex items-center justify-center gap-x-6">
                            <a
                                href="#engines"
                                className="rounded-3xl bg-transparent border-2 border-neutral-400 px-9 pt-2.5 pb-2 text-sm font-normal text-neutral-300 shadow-sm hover:bg-neutral-200/10 duration-150 tracking-[.2em] uppercase"
                            >
                                {t("get_started")}
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <BrainCog_Born brainknow={true} />
            <Services/>
            <Datasets stream={true} />

        </div>
    )
}

export default Home