// import '../styles/Common.css';
import {useTranslation, Trans} from "react-i18next";
import {Helmet} from 'react-helmet-async';
import SEO from "./SEO";
import heroBackgroundImage from '../assets/images/hero_background/publications_hero.jpg';
import {useEffect, useRef} from "react";

const publications = [
    {
        authors: 'Wenxuan Pan, Feifei Zhao, Zhuoya Zhao, Yi Zeng.',
        title: 'Brain-inspired Evolutionary Architectures for Spiking Neural Networks',
        journal: 'IEEE Transactions on Artificial Intelligence, 2024',
        url: 'https://arxiv.org/abs/2309.05263'
    },
    {
        "authors": "Ammar Younas, Yi Zeng",
        "title": "Proposing Central Asian AI ethics principles: a multilevel approach for responsible AI",
        "journal": "AI and Ethics, 2024",
        "url": "https://link.springer.com/article/10.1007/s43681-024-00505-7"
    },
    {
        "authors": "Yiting Dong, Xiang He, Guobin Shen, Dongcheng Zhao, Yang Li, Yi Zeng",
        "title": "EventZoom: A Progressive Approach to Event-Based Data Augmentation for Enhanced Neuromorphic Vision",
        "journal": "arXiv preprint arXiv:2405.18880, 2024",
        "url": "https://arxiv.org/abs/2405.18880"
    },
    {
        "authors": "Ammar Younas, Yi Zeng",
        "title": "A Philosophical Inquiry into AI-Inclusive Epistemology",
        "journal": "SSRN 4822881, 2024",
        "url": "https://papers.ssrn.com/sol3/papers.cfm?abstract_id=4822881"
    },
    {
        "authors": "Zhuoya Zhao, Feifei Zhao, Shiwen Wang, Yinqian Sun, Yi Zeng",
        "title": "A Brain-inspired Theory of Collective Mind Model for Efficient Social Cooperation",
        "journal": "IEEE Transactions on Artificial Intelligence, 2024",
        "url": "https://ieeexplore.ieee.org/abstract/document/10518133/"
    },
    {
        "authors": "Xiang He, Yang Li, Dongcheng Zhao, Qingqun Kong, Yi Zeng",
        "title": "MSAT: Biologically Inspired Multistage Adaptive Threshold for Conversion of Spiking Neural Networks",
        "journal": "Neural Computing and Applications, 2024",
        "url": "https://link.springer.com/article/10.1007/s00521-024-09529-w"
    },
    {
        "authors": "Yang Li, Yinqian Sun, Xiang He, Yiting Dong, Dongcheng Zhao, Yi Zeng",
        "title": "Parallel Spiking Unit for Efficient Training of Spiking Neural Networks",
        "journal": "IJCNN, 2024",
        "url": "https://arxiv.org/abs/2402.00449"
    },
    {
        "authors": "Guobin Shen, Dongcheng Zhao, Tenglong Li, Jindong Li, Yi Zeng",
        "title": "Are Conventional SNNs Really Efficient? A Perspective from Network Quantization",
        "journal": "Proceedings of the IEEE/CVF Conference on Computer Vision and Pattern Recognition 2024",
        "url": "https://cvpr.thecvf.com/virtual/2024/poster/29731"
    },
    {
        "authors": "Yuwei Wang, Yi Zeng",
        "title": "A Brain-inspired Computational Model for Human-like Concept Learning",
        "journal": "ArXiv preprint arXiv: 2401.06471 (2024).",
        "url": "https://arxiv.org/abs/2401.06471"
    },
    {
        authors: 'Jindong Li, Tenglong Li, Guobin Shen, Dongcheng Zhao, Qian Zhang, Yi Zeng.',
        title: 'Revealing Untapped DSP Optimization Potentials for FPGA-Based Systolic Matrix Engines',
        journal: '34th International Conference on Field-Programmable Logic and Applications, 2024',
        url: 'javascript:void(0)'
    },
    {
        authors: 'Sicheng Shen, Dongcheng Zhao, Guobin Shen, Yi Zeng.',
        title: 'TIM: An Efficient Temporal Interaction Module for Spiking Transformer',
        journal: 'IJCAI, 2024',
        url: 'https://arxiv.org/abs/2401.11687'
    },
    {
        authors: 'Yiting Dong, Yang Li, Dongcheng Zhao, Guobin Shen, Yi Zeng.',
        title: 'Bullying10K: A Large-Scale Neuromorphic Dataset towards Privacy-Preserving Bullying Recognition',
        journal: 'Advances in Neural Information Processing Systems, 2024, 36',
        url: 'https://arxiv.org/abs/2306.11546'
    },
    {
        authors: 'Yuwei Wang, Enmeng Lu, Zizhe Ruan, Yao Liang, Yi Zeng.',
        title: 'Stream: Social data and knowledge collective intelligence platform for TRaining Ethical AI Models',
        journal: 'AI & SOCIETY, 2024: 1-9',
        url: 'https://link.springer.com/article/10.1007/s00146-023-01851-6'
    },
    {
        authors: 'Aorigele Bao, Yi Zeng.',
        title: 'Understanding the dilemma of explainable artificial intelligence: a proposal for a ritual dialog framework',
        journal: 'Humanities and Social Sciences Communications 11.1 (2024): 1-9',
        url: 'https://www.nature.com/articles/s41599-024-02759-2'
    },
    {
        authors: 'Aorigele Bao, Yi Zeng.',
        title: 'Embracing grief in the age of deathbots: a temporary tool, not a permanent solution',
        journal: 'Ethics and Information Technology, 2024, 26(1): 7',
        url: 'https://link.springer.com/article/10.1007/s10676-024-09744-y'
    },
    {
        authors: 'Guobin Shen, Dongcheng Zhao, Xiang He, Linghao Feng, Yiting Dong, Jihang Wang, Qian Zhang, Yi Zeng.',
        title: 'Neuro-Vision to Language: Image Reconstruction and Language enabled Interaction via Brain Recordings',
        journal: 'arXiv preprint arXiv: 2404.19438 (2024)',
        url: 'https://arxiv.org/abs/2404.19438'
    },
    {
        authors: 'Cunqing Huangfu, Kang Sun, Yi Zeng, Yuwei Wang, Dongsheng Wang, Zizhe Ruan.',
        title: 'BrainKnow -- Extracting, Linking, and Associating Neuroscience Knowledge',
        journal: 'arXiv preprint arXiv:2403.04346 (2024)',
        url: 'https://arxiv.org/abs/2403.04346'
    },
    {
        authors: 'Yuxuan Zhao, Enmeng Lu, Yi Zeng.',
        title: 'Brain-inspired bodily self-perception model for robot rubber hand illusion',
        journal: 'Patterns, 2023, 4(12)',
        url: 'https://www.cell.com/patterns/fulltext/S2666-3899(23)00289-1'
    },
    {
        authors: 'Aorigele Bao, Yi Zeng, Enmeng Lu.',
        title: 'Mitigating emotional risks in human-social robot interactions through virtual interactive environment indication',
        journal: 'Humanities and Social Sciences Communications, 2023, 10(1): 1-9',
        url: 'https://www.nature.com/articles/s41599-023-02143-6'
    },
    {
        authors: 'Yi Zeng, Enmeng, Lu, Kang Sun.',
        title: 'Principles on symbiosis for natural life and living artificial intelligence',
        journal: 'AI and Ethics, 2023: 1-6',
        url: 'https://link.springer.com/article/10.1007/s43681-023-00364-8'
    },

    {
        authors: 'Guobin Shen, Dongcheng Zhao, Yiting Dong, Yang Li, Jindong Li, Yi Zeng.',
        title: 'Astrocyte-Enabled Advancements in Spiking Neural Networks for Large Language Modeling',
        journal: 'arXiv preprint arXiv:2312.07625 (2023)',
        url: 'https://arxiv.org/abs/2312.07625'
    }
];


const Publications = () => {
    const {t, i18n} = useTranslation(['publications', 'common', 'title'])
    const isChinese = i18n.language === 'zh';

    const elementsRef = useRef([]);
    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('animate__animated', 'animate__slideInLeft');
                    } else {
                        entry.target.classList.remove('animate__animated');
                    }
                });
            },
            {threshold: 0}
        );
        elementsRef.current.forEach(element => {
            if (element) {
                observer.observe(element);
            }
        });
        return () => {
            elementsRef.current.forEach(element => {
                if (element) {
                    observer.unobserve(element);
                }
            });
        };
    }, [publications]);

    return (
        <div>
            <SEO title={t('publications', {ns: 'html_title'})} />
            <div className={"bg-cover bg-neutral-300 bg-blend-multiply bg-center bg-no-repeat bg-fixed"}
                 style={{backgroundImage: `url(${heroBackgroundImage})`}}
            >
                {/*hero*/}
                <div className={"flex justify-center items-start 2xl:items-center bg-black/70"}>
                    <div className={"w-full lg:w-[1500px] h-[700px] flex flex-col justify-center items-start p-6"}>
                        <div className={"animate__animated animate__fadeInDown text-white/60 text-2xl font-extralight"}>{t('hero_headline_1')}</div>
                        <div className={"animate__animated animate__fadeInUp bg-clip-text text-transparent drop-shadow-2xl bg-gradient-to-b from-white/90 to-white/20 text-5xl md:text-6xl font-bold mt-5 leading-normal"}>{t('hero_headline_2')}</div>
                    </div>
                </div>

                {/*cover banner*/}
                <div className={"animate__animated animate__fadeInLeft bg-black/20 w-full md:max-h-[280px] pb-7 relative flex flex-col justify-end items-center"}>
                    <div className={"flex justify-center items-end flex-col md:flex-row"}>
                        <div className={"pt-12 sm:pt-6 pb-8 sm:pb-4 pl-4 pr-4 sm:pr-16"}>
                            <div className={"animate__animated animate__fadeInDown flex flex-col md:flex-row justify-start md:items-center items-start"}>
                                <a href={"https://www.cell.com/patterns/fulltext/S2666-3899(23)00144-7"} className={"mt-[-25px]"}>
                                    {/*<span className={"text-black text-5xl font-black"}>{t('cover_headline_1')}</span>*/}
                                    <img className={"max-h-[80px] inline"} src={"/logo/braincog_logo_word_2.png"} alt={'braincog_logo_word_2'}/>
                                </a>
                                <span className={"text-[#4694f4] text-[45px] font-bold md:ml-4 text-nowrap"}>{t('cover_headline_1_suffix')}</span>
                            </div>
                            <a href={"https://www.cell.com/patterns/issue?pii=S2666-3899(22)X0009-3"} className={""}>
                                <div className={"animate__animated animate__fadeInUp text-neutral-200 text-5xl font-black leading-tight tracking-normal mt-2"}>{t('cover_headline_2')}</div>
                            </a>
                        </div>
                        <div className={"animate__animated animate__fadeInUpBig m-4 sm:m-2 p-[6px] shadow bg-neutral-200"}>
                            <img className={"max-h-[600px]"} src={"/images/publications/braincog_patterns_cover.jpg"} alt={'braincog_patterns_cover'}/>
                        </div>
                    </div>
                </div>

                <div className={"h-16 bg-black/75"}></div>

                {/*publications*/}
                <div className={"content-container bg-black/75"}>
                    <div className={"flex flex-col justify-center items-start max-w-4xl mx-auto px-6 py-36"}>
                        <h3 className={"animate__animated animate__fadeInUp text-medium text-neutral-200 text-3xl mb-10"}>{t('publications_title')}</h3>
                        <h3 className={"animate__animated animate__fadeInUp font-extralight text-neutral-300 text-xl mb-14"}>{t('publications_intro')}</h3>
                        <div className={"flex flex-col justify-start align-items-center space-y-6 mb-4"}>
                            {publications.map((publication, index) => (
                                <div
                                    key={index}
                                    ref={el => elementsRef.current[index] = el}
                                    className="animate__animated animate__fadeInLeft py-4 px-6 bg-gradient-to-tr from-violet-800/20 to-fuchsia-900/30 bg-[length:100%_100%] bg-center hover:bg-gradient-to-tr hover:from-violet-700/20 hover:to-fuchsia-800/30 border border-violet-900 shadow-md hover:shadow-lg group transition-all duration-500"
                                >
                                    <span className="text-neutral-400 text-base block max-h-0 overflow-hidden group-hover:max-h-20 transition-all duration-500 ease-in-out">{publication.authors}</span>
                                    <a href={publication.url} rel={"noreferrer"}>
                                        <span className="text-neutral-300 text-lg group-hover:text-white block font-normal mt-2 mb-2 transition-all duration-500 ease-in-out font-serif">{publication.title}</span>
                                    </a>
                                    <span className="text-neutral-400 text-base block max-h-0 overflow-hidden group-hover:max-h-20 transition-all duration-500 ease-in-out">{publication.journal}</span>
                                </div>
                            ))}
                        </div>

                        {/*                    <h3>{t('team')}</h3>
                    <p>{t('principal')}：<a href={"http://braincog.ai/~yizeng/"} target={"_blank"}
                                           rel={"noreferrer"}>{t('zeng')}</a><br/>{t('others')}</p>*/}
                    </div>
                </div>

            </div>
        </div>
    );
};

export default Publications;
